"use strict";
/**
 * スクロール量に合わせてクラスの付与、及び削除
 * @module toggleClassByScrolling
 * @param {Object} object 設定情報オブジェクト
 * @param {string} object.target クラス名を付与する要素のセレクター
 * @param {string} object.grantClass 付与するクラス名
 * @return {void} 返り値なし
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleClassByScrolling = void 0;
var toggleClassByScrolling = function (object) {
    var flag = false;
    var defaultOffsetY = 100;
    var element = document.querySelector(object.target);
    var amount = element.dataset.offsetY === undefined
        ? defaultOffsetY
        : parseInt(element.dataset.offsetY, 10);
    // 処理
    window.addEventListener('scroll', function () {
        if (flag === false && window.pageYOffset > amount) {
            // Class付与
            element.classList.add(object.grantClass);
            flag = true;
        }
        else if (flag === true && window.pageYOffset <= amount) {
            // class削除
            element.classList.remove(object.grantClass);
            flag = false;
        }
    });
};
exports.toggleClassByScrolling = toggleClassByScrolling;
