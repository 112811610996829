"use strict";
/**
 * cms取得モジュール ie以外
 * @module getCms
 * @param {string} selector CMSを表示する要素のセレクター
 * @param {string} url 取得するCMSのURL
 * @return {void} 返り値なし
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCms = void 0;
var getCms = function (selector, url) {
    var target = document.querySelector(selector);
    if (target !== null) {
        var result = fetch(url);
        result
            .then(function (res) {
            if (!res.ok) {
                throw new Error(res.status + " " + res.statusText);
            }
            return res.text();
        })
            .then(function (text) { return (target.innerHTML = text); })
            .catch(function (error) { return (target.innerHTML = error.message); });
    }
};
exports.getCms = getCms;
