"use strict";
/**
 * ページ内リンクかどうかの判定
 * @module 現在のページのファイル名を返す
 * @return {string} - ファイル名
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCurrentPagefileName = void 0;
var getCurrentPagefileName = function () {
    return location.href.match('.+/(.+?)([?#;].*)?$')[1];
};
exports.getCurrentPagefileName = getCurrentPagefileName;
