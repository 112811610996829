"use strict";
/**
 * サイト内リンクかどうかの判定
 * @module isInternalLink
 * @param {string} url 判定するURL文字列
 * @return {boolean} - 判定結果
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInternalLink = void 0;
var isInternalLink = function (url) {
    if (typeof url !== 'string') {
        return false;
    }
    else if (url.indexOf(document.domain) !== -1) {
        return true;
    }
    else if (url.match(/^https?:\/\//)) {
        return false;
    }
    return true;
};
exports.isInternalLink = isInternalLink;
