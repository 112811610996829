"use strict";
/**
 * viewportに表示されるコンテンツに応じてターゲット要素のデータ属性を変更する
 * @module areaOvserve
 * @param {Object} object セレクター情報オブジェクト
 * @param {string} object.grantedElement data属性値を付与する要素のセレクター
 * @param {string} object.observedElements 監視する要素のセレクター
 * @return {void} 返り値なし
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.areaOvserve = void 0;
var areaOvserve = function (object) {
    var grantedElement = object.grantedElement
        ? document.querySelector(object.grantedElement)
        : document.body;
    var observedElements = document.querySelectorAll(object.observedElements);
    if (observedElements.length) {
        grantedElement.setAttribute('data-viewarea', observedElements[0].id);
    }
    // IntersectionObserverオプション
    var myOptions = {
        rootMargin: '-50% 0px -50% 0px',
        threshold: 0
    };
    // IntersectionObserverコールバック
    var myCallback = function (entries) {
        entries.forEach(function (entry) {
            if (entry.isIntersecting) {
                grantedElement.setAttribute('data-viewarea', entry.target.id);
            }
        });
    };
    // インスタンス生成
    var myObserve = new IntersectionObserver(myCallback, myOptions);
    // 監視の開始
    observedElements.forEach(function (target) {
        myObserve.observe(target);
    });
};
exports.areaOvserve = areaOvserve;
